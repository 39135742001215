import './App.css';
import { Routes, Route } from 'react-router-dom';
import PageResetComp from './components/PageResetComp';
import DynamicCompLoader from './components/DynamicCompLoader';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import { ErrorPage } from './ErrorPage';

import TagManager from 'react-gtm-module';
 
const tagManagerArgs = { gtmId: 'GTM-PH3QV7P' }
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="App">
      <PageResetComp />
      <Routes>
        <Route exact path='/integrated-solar-roof' element={<DynamicCompLoader dynamicComp='./landing-pages/integrated-solar-roof/IntegratedSolarRoofComp' />} />
        <Route exact path='/integrated-solar-roof/success' element={<DynamicCompLoader dynamicComp='./landing-pages/integrated-solar-roof/Success' />} />
        <Route exact path='/integrated-solar-roof/error' element={<DynamicCompLoader dynamicComp='./landing-pages/integrated-solar-roof/Error' />} />
        
        <Route exact path="/" element={<DynamicCompLoader dynamicComp='./website/pages/Home' />} />
        <Route exact path='/about' element={<DynamicCompLoader dynamicComp='./website/pages/About' />} />
        <Route exact path='/portfolio' element={<DynamicCompLoader dynamicComp='./website/pages/Portfolio' />} />
        <Route exact path='/technical-specs/:id' element={<DynamicCompLoader dynamicComp='./website/pages/TechnicalSpecifications' />} />
        <Route exact path='/products/:id' element={<DynamicCompLoader dynamicComp='./website/pages/Products' />} />

        <Route exact path='/news' element={<DynamicCompLoader dynamicComp='./website/pages/NewsAndEvent' />} />
        <Route exact path='/blog' element={<DynamicCompLoader dynamicComp='./website/pages/Blog' />} />
        <Route exact path='/blog/:bdheading' element={<DynamicCompLoader dynamicComp='./website/pages/BlogDetails' />} />
        <Route exact path='/contact' element={<DynamicCompLoader dynamicComp='./website/pages/Contact' />} />
        <Route exact path='/terms-conditions' element={<DynamicCompLoader dynamicComp='./website/pages/TermsConditions' />} />
        <Route exact path='/privacy-policy' element={<DynamicCompLoader dynamicComp='./website/pages/PrivacyPolicy' />} />
        <Route exact path='/cookie-policy' element={<DynamicCompLoader dynamicComp='./website/pages/CookiePolicy' />} />
        <Route exact path='/solar-panels/:title' element={<DynamicCompLoader dynamicComp='./website/pages/SolarPanels' />} />
        <Route exact path='/success' element={<DynamicCompLoader dynamicComp='./website/pages/Success' />} />
        <Route exact path='/error' element={<DynamicCompLoader dynamicComp='./website/pages/Error' />} />
        <Route exact path='/become-partner' element={<DynamicCompLoader dynamicComp='./website/pages/BecomePartner' />} />
        <Route exact path='/solar-calculator' element={<DynamicCompLoader dynamicComp='./website/pages/SolarCalculator' />} />

        <Route path='*' element={<ErrorPage title="404!" desc="Opps! The page you are trying to reach is not found, please check the URL and try again." path="/" pathName="Home" />} />
      </Routes>
    </div>
  );
}

export default App;