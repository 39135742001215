import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { S3Url } from '../../../Config';

import Header from '../common/Header';
import Footer from '../common/Footer';
import { InsideBannerList } from './Innerbanner';
// import { Link } from 'react-router-dom';
import { API_URL } from '../../../Config';
import MobileVerificationComp from '../common/MobileVerificationComp';


const BecomePartner = () => {
    // Mobile Number Validation With API Intigration Start
    const [becomeDealerSubmitBtnDisabled, setBecomeDealerSubmitBtnDisabled] = useState(true);
    
    // Inside banner start
    const [state, setState] = useState("");
    useEffect(() => {
        let becomeDealerInsideSlider = InsideBannerList.filter((item) => item.title === 'become-partner');
        setState(becomeDealerInsideSlider[0]);
    }, []);
    // Inside banner end


    // State District API Intigration Start
    const [stDst, setstDst] = useState();
    const [distData, setDistData] = useState();
    const navigate = useNavigate();

    const fetchStateDistrictInfo = async () => {
        const stateDistrictRes = await fetch(S3Url+"assets/locations/json/indianStatesCities.json");
        const stateDistrictResData = await stateDistrictRes.json();
        setstDst(stateDistrictResData);
    }

    const handleStateDistrict = (e) => {
        const getDistrict = stDst.filter((distitem) => distitem.State === e);
        setDistData(getDistrict);
    }
    useEffect(() => { fetchStateDistrictInfo(); }, []);
    // State District API Intigration End
    

    // Form Validation With API Intigration Start
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            country: 'India',
            state: '',
            district: '',
            city: '',
            pincode: '',
            street: '',
            proposed_investment: '',
            occupation: '',
            area_for_showroom: '',
            area_for_workshop: '',
            experience_in_two_wheeler_business: '',
            current_business_turnover: '',
            years_in_current_business: '',
            current_business_details: '',
        },

        validate: (values) => {
            let errors = {};
            if (!values.first_name) { errors.first_name = 'First name is required'; }
            if (!values.last_name) { errors.last_name = 'Last name is required'; }
            if (!values.email) { errors.email = 'Email is required'; } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) { errors.email = 'Invalid email address'; }
            if (!values.phone) { errors.phone = 'Mobile number is required'; } else if(values.phone.length !== 10){ errors.phone = 'Enter valid Mobile number!'; } else if ( !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.phone)) { errors.phone = 'Please enter numbers format only'; }
            if (!values.country) { errors.country = 'Country is required'; }
            if (!values.state) { errors.state = 'State is required'; }
            if (values.state){ handleStateDistrict(values.state) }
            if (!values.district) { errors.district = 'District is required'; }
            if (!values.city) { errors.city = 'City is required'; }
            if (!values.pincode) { errors.pincode = 'Pin code is required'; } else if ( !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.pincode)) { errors.pincode = 'Please enter numbers format only'; }
            if (!values.street) { errors.street = 'Address is required'; }
            if (!values.proposed_investment) { errors.proposed_investment = 'Proposed investment is required'; }
            if (!values.occupation) { errors.occupation = 'Occupation is required'; }
            if (!values.area_for_showroom) { errors.area_for_showroom = 'Area for showroom is required'; }
            if (!values.area_for_workshop) { errors.area_for_workshop = 'Area for workshop is required'; }
            if (!values.experience_in_two_wheeler_business) { errors.experience_in_two_wheeler_business = 'Experience in two wheeler business is required'; }
            if (!values.current_business_turnover) { errors.current_business_turnover = 'Current business turnover is required'; }
            if (!values.years_in_current_business) { errors.years_in_current_business = 'Years in current business is required'; }
            if (!values.current_business_details) { errors.current_business_details = 'Current business details is required'; }
            return errors;
        },

        onSubmit:(values) => {
            const formData = new FormData();
            formData.append("first_name", values.first_name)
            formData.append("last_name", values.last_name)
            formData.append("email", values.email)
            formData.append("phone", values.phone)
            formData.append("country", values.country)
            formData.append("state", values.state)
            formData.append("district", values.district)
            formData.append("city", values.city)
            formData.append("pincode", values.pincode)
            formData.append("street", values.street)
            formData.append("proposed_investment", values.proposed_investment)
            formData.append("occupation", values.occupation)
            formData.append("area_for_showroom", values.area_for_showroom)
            formData.append("area_for_workshop", values.area_for_workshop)
            formData.append("experience_in_two_wheeler_business", values.experience_in_two_wheeler_business)
            formData.append("current_business_turnover", values.current_business_turnover)
            formData.append("years_in_current_business", values.years_in_current_business)
            formData.append("current_business_details", values.current_business_details)
            // formData.append("category", "Item enquiry")
            // formData.append("campaign_id", campaignId)

            axios.post(`${API_URL}atum/dealers/subscription/create`, formData)
            .then(response=>
                {
                    response.data.status === 200 ? navigate('/success') : navigate('/error')
                    formik.resetForm();
                }
            ).catch( err => console.log(err) )
        }
    });
    // Form Validation With API Intigration End


  return (
    <>
        <Header/>
            <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
                <div className="container">
                    <div className="row">
                        <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
                    </div>
                </div>
            </section>
            <section className="become-dealer-form-section py-5">
                <div className="container">
                    <div className="row">
                        <div className="become-dealer-card border-0 rounded-4 shadow px-5 py-4 mx-auto">
                            <div className="row">
                                <form onSubmit={formik.handleSubmit} className="m-0 p-0" autoComplete="off">
                                    <div className="row">
                                        <h4 className="primary-text text-capitalize fs-6 fw-bold m-0 px2 py-1">Personal Information</h4>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <input type="text" className="form-control" name="first_name" placeholder="First Name*" onChange={formik.handleChange} value={formik.values.first_name} />
                                            {formik.errors.first_name ? <span className="text-danger fs-8">{formik.errors.first_name}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <input type="text" className="form-control" name="last_name" placeholder="Last Name*" onChange={formik.handleChange} value={formik.values.last_name} />
                                            {formik.errors.last_name ? <span className="text-danger fs-8">{formik.errors.last_name}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <input type="text" className="form-control" name="email" placeholder="Email*" onChange={formik.handleChange} value={formik.values.email} />
                                            {formik.errors.email ? <span className="text-danger fs-8">{formik.errors.email}</span> : null}
                                        </div>
                                        <MobileVerificationComp setBecomeDealerSubmitBtnDisabled={setBecomeDealerSubmitBtnDisabled} mobileChangeEvent={formik.handleChange} formik={formik} />
                                    </div>

                                    <div className="row mt-4">
                                        <h4 className="primary-text text-capitalize fs-6 fw-bold m-0 px2 py-1">Address Information</h4>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="country" onChange={formik.handleChange} value={formik.values.country}>
                                                <option value="India" defaultValue>India</option>
                                            </select>
                                            {formik.errors.country ? <span className="text-danger fs-8">{formik.errors.country}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="state" onChange={formik.handleChange} value={formik.values.state}>
                                                <option defaultValue>State</option>
                                                {stDst && Array.from(new Set(stDst.map(obj => obj.State))).map((State, index) => {
                                                    return (<option value={State} key={index}>{State}</option>)
                                                })}
                                            </select>
                                            {formik.errors.state ? <span className="text-danger fs-8">{formik.errors.state}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="district" onChange={formik.handleChange} value={formik.values.district}>
                                                <option defaultValue>District</option>
                                                {distData && distData.map((distObj, index) => {
                                                    return (
                                                        <option value={distObj.District} key={index}>{distObj.District}</option>
                                                    )
                                                })}
                                            </select>
                                            {formik.errors.district ? <span className="text-danger fs-8">{formik.errors.district}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <input type="text" className="form-control" name="city" placeholder="City" onChange={formik.handleChange} value={formik.values.city} />
                                            {formik.errors.city ? <span className="text-danger fs-8">{formik.errors.city}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <input type="text" className="form-control" name="pincode" maxLength="6" placeholder="Pin Code" onChange={formik.handleChange} value={formik.values.pincode} />
                                            {formik.errors.pincode ? <span className="text-danger fs-8">{formik.errors.pincode}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 px-2">
                                            <textarea className="form-control" name="street" rows="3" onChange={formik.handleChange} value={formik.values.street} ></textarea>
                                            {formik.errors.street ? <span className="text-danger fs-8">{formik.errors.street}</span> : null}
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <h4 className="primary-text text-capitalize fs-6 fw-bold m-0 px2 py-1">Other Information</h4>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="proposed_investment" onChange={formik.handleChange} value={formik.values.proposed_investment}>
                                                <option defaultValue>Proposed investment</option>
                                                <option value="30 lakhs">30 lakhs</option>
                                                <option value="40 lakhs">40 lakhs</option>
                                                <option value="50 lakhs">50 lakhs</option>
                                            </select>
                                            {formik.errors.proposed_investment ? <span className="text-danger fs-8">{formik.errors.proposed_investment}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="occupation" onChange={formik.handleChange} value={formik.values.occupation}>
                                                <option defaultValue>Occupation</option>
                                                <option value="Business">Business</option>
                                                <option value="Salaried">Salaried</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            {formik.errors.occupation ? <span className="text-danger fs-8">{formik.errors.occupation}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="area_for_showroom" onChange={formik.handleChange} value={formik.values.area_for_showroom}>
                                                <option defaultValue>Area for showroom (min 1000 Sq.Ft)</option>
                                                <option value="Owned">Owned</option>
                                                <option value="Rented">Rented</option>
                                                <option value="Not yet finalized">Not yet finalized</option>
                                            </select>
                                            {formik.errors.area_for_showroom ? <span className="text-danger fs-8">{formik.errors.area_for_showroom}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="area_for_workshop" onChange={formik.handleChange} value={formik.values.area_for_workshop}>
                                                <option defaultValue>Area for workshop (min 500-800 Sq.Ft)</option>
                                                <option value="Owned">Owned</option>
                                                <option value="Rented">Rented</option>
                                                <option value="Not yet finalized">Not yet finalized</option>
                                            </select> 
                                            {formik.errors.area_for_workshop ? <span className="text-danger fs-8">{formik.errors.area_for_workshop}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <select className="form-select" name="experience_in_two_wheeler_business" onChange={formik.handleChange} value={formik.values.experience_in_two_wheeler_business}>
                                                <option defaultValue>Do you have experience in two wheeler automobile business</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            {formik.errors.experience_in_two_wheeler_business ? <span className="text-danger fs-8">{formik.errors.experience_in_two_wheeler_business}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                                            <input type="text" className="form-control" name="current_business_turnover" placeholder="Current business turnover" onChange={formik.handleChange} value={formik.values.current_business_turnover} />
                                            {formik.errors.current_business_turnover ? <span className="text-danger fs-8">{formik.errors.current_business_turnover}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 px-2">
                                            <input type="text" className="form-control" name="years_in_current_business" placeholder="Since how many years you are doing your current business" onChange={formik.handleChange} value={formik.values.years_in_current_business} />
                                            {formik.errors.years_in_current_business ? <span className="text-danger fs-8">{formik.errors.years_in_current_business}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 px-2">
                                            <textarea className="form-control" name="current_business_details" placeholder="Details of current business" rows="3" onChange={formik.handleChange} value={formik.values.current_business_details}></textarea>
                                            {formik.errors.current_business_details ? <span className="text-danger fs-8">{formik.errors.current_business_details}</span> : null}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 px-2 d-flex justify-content-center">
                                            <input type="submit" value="Submit" className="btn btn-danger fw-semibold global-primary-bg px-3" disabled={becomeDealerSubmitBtnDisabled} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </>
  )
}

export default BecomePartner