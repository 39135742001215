import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import Header from '../common/Header';
import Footer from '../common/Footer';
import solarCalculatorJson from '../../../solar-calculator.json';
import CircularSlider from '@fseehawer/react-circular-slider';

const SolarCalculator = () => {

    const solarCalculator = solarCalculatorJson['product-props'];
    const calculatorProps = solarCalculatorJson['calculator-props'];
    
    // INSIDE BANNER START
    const [state, setState] = useState({});
    useEffect(() => {
        let solarCalculatorInsideSlider = InsideBannerList.filter((item) => item.title === 'solar-calculator');
        setState(solarCalculatorInsideSlider[0]);
    }, []);
    // INSIDE BANNER END

    const [totalSavingsWithStructure, setTotalSavingsWithStructure] = useState(0);
    const [totalSavingsWithoutStructure, setTotalSavingsWithoutStructure] = useState(0);
    const [systemSize, setSystemSize] = useState(0);
    const [systemPriceWithStructure, setSystemPriceWithStructure] = useState(0);
    const [systemPriceWithoutStructure, setSystemPriceWithoutStructure] = useState(0);
    const [outputPerMonth, setOutputPerMonth] = useState(0);

    const [product, setProduct] = useState('Conventional Roof');
    const [propertyTypeList, setPropertyTypeList] = useState(Object.keys(solarCalculator[product]));

    const [propertyType, setPropertyType] = useState(Object.keys(solarCalculator[product])[0]);
    const [electricityBill, setElectricityBill] = useState(0);
    const [unitsConsumedPerMonth, setUnitsConsumedPerMonth] = useState(0);
    const [rooftopArea, setRooftopArea] = useState(0);
    const [noOfYears, setNoOfYears] = useState(calculatorProps["default-years"]-1);

    useEffect(() => {
        if(electricityBill !== "" && electricityBill !== 0 && unitsConsumedPerMonth !== "" && unitsConsumedPerMonth !== 0 && rooftopArea !== "" && rooftopArea !== 0){
            var areaRequiredPerKW = solarCalculator[product][propertyType]["Area Required Per KW"];
            var kwUnitsGenerationPerDay = solarCalculator[product][propertyType]["1 KW Units Generation per Day"];
            var projectCostWithStructure = solarCalculator[product][propertyType]["Project Cost"]["With Structure"];
            var projectCostWithoutStructure = solarCalculator[product][propertyType]["Project Cost"]["Without Structure"];

            var ratePerUnit = electricityBill/unitsConsumedPerMonth;

            var systemSizeArea = rooftopArea/areaRequiredPerKW;
            var systemSizeUnit = unitsConsumedPerMonth/(kwUnitsGenerationPerDay*30);

            var systemSizeTemp = Math.min(systemSizeArea, systemSizeUnit);
            setSystemSize(() => { return systemSizeTemp });

            var outputPerMonthTemp = systemSizeTemp*kwUnitsGenerationPerDay*30;
            setOutputPerMonth(() => { return outputPerMonthTemp });

            var systemPriceWithStructureTemp = projectCostWithStructure*outputPerMonthTemp*systemSizeTemp;
            setSystemPriceWithStructure(() => { return systemPriceWithStructureTemp });
            
            var systemPriceWithoutStructureTemp = projectCostWithoutStructure*outputPerMonthTemp*systemSizeTemp;
            setSystemPriceWithoutStructure(() => { return systemPriceWithoutStructureTemp });

            if(rooftopArea > 0 && unitsConsumedPerMonth > 0) {
                var totalSavingsWithStructureTemp = unitsConsumedPerMonth*noOfYears*12*ratePerUnit-systemPriceWithStructureTemp;
                setTotalSavingsWithStructure(() => { return totalSavingsWithStructureTemp });

                var totalSavingsWithoutStructureTemp = unitsConsumedPerMonth*noOfYears*12*ratePerUnit-systemPriceWithoutStructureTemp;
                setTotalSavingsWithoutStructure(() => { return totalSavingsWithoutStructureTemp });
            }else{
                setTotalSavingsWithStructure(0);
                setTotalSavingsWithoutStructure(0);
            }
        }
    }, [solarCalculator, product, propertyType, electricityBill, unitsConsumedPerMonth, rooftopArea, noOfYears]);

    useEffect(() => {
        setTotalSavingsWithStructure(0);
        setTotalSavingsWithoutStructure(0);
        setSystemSize(0);
        setSystemPriceWithStructure(0);
        setSystemPriceWithoutStructure(0);
        setOutputPerMonth(0);

        setPropertyTypeList(Object.keys(solarCalculator[product]));
        setPropertyType(Object.keys(solarCalculator[product])[0]);

        setElectricityBill(0);
        setRooftopArea(0);
        setUnitsConsumedPerMonth(0);
        setNoOfYears(calculatorProps["default-years"]-1);
    }, [product, solarCalculator, calculatorProps]);

  return (
    <>
        <Header/>
            <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
                <div className="container">
                    <div className="row">
                        <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
                    </div>
                </div>
            </section>
            <section className="calculator_div">
                <div className="container section-title text-center">
                    <h2 className="section_title">Use our solar calculator <br className="hidden-xs" /> <span className="small_section_title"> find out how much money you can save with rooftop solar</span> </h2>
                    <div id="scrollToCalli" className="hidden-xs"></div>
                </div>
                <div className="container cal_top_space">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 m-0 p-0 left_side_div_border">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left_side_div">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div">
                                    <div className="row m-0 p-0 justify-content-center">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5">
                                            <h3 className="calculate_field_title pb-2">Choose Product <sup>*</sup></h3>
                                            <select className="form-select" onChange={(a) => { setProduct((oldProduct) => { setPropertyTypeList(Object.keys(solarCalculator[a.target.value])); return a.target.value; }); }}>
                                                {Object.keys(solarCalculator).map((p) => (<option key={p.replaceAll(' ','')} value={p}>{p}</option>))}
                                            </select>
                                        </div>

                                        <h3 className="calculate_field_title pb-2">Property type <sup>*</sup></h3>
                                        {
                                            propertyTypeList.map((pt) => (
                                                <div key={solarCalculator[product][pt].info.name.replaceAll(' ','')} className={`col-6 d-flex flex-column align-items-center justify-content-center`} onClick={ () => setPropertyType(solarCalculator[product][pt].info.name)}>
                                                    <div className={`w-100 d-flex justify-content-center align-items-center rounded propertytype-block ${(propertyType === solarCalculator[product][pt].info.name) && 'active'}`}>
                                                        <div style={{ fontSize: '2em' }}>{solarCalculator[product][pt].info.icon}</div>
                                                    </div>
                                                    <p className="fs-6 fw-semibold mt-2 mb-0">{solarCalculator[product][pt].info.name}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div tab_spacing">
                                    <div className="row m-0 p-0">
                                        <h3 className="calculate_field_title pb-2">Electricity Bill (Rs.) <sup>*</sup></h3>
                                        <div className="col-12">
                                            <div className="slider_area">
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between slider_div">
                                                        <span className="pe-2">0</span>
                                                        <input type="range" className="w-100 custom-range" id="electricityBill" min={calculatorProps['electricity-bill'].min} max={calculatorProps['electricity-bill'].max} step="1" value={(electricityBill === "") ? calculatorProps['electricity-bill'].min : electricityBill} onChange={(a) => { if(a.target.value <= calculatorProps['electricity-bill'].max){ setElectricityBill((oldVal) => { return a.target.value }); } }} />
                                                        <span className="ps-2">5L</span>
                                                    </div>
                                                    <div className="d-flex flex-column flex-md-row justify-content-start align-items-center slider_input_div">
                                                        <span className="before_input">Rs.</span>
                                                        <input type="number" className="form-control22 get_electricityBill ms-2 me-2" min={calculatorProps['electricity-bill'].min} max={calculatorProps['electricity-bill'].max} value={electricityBill} onChange={(a) => { setElectricityBill((oldVal) => { return (a.target.value > calculatorProps['electricity-bill'].max) ? calculatorProps['electricity-bill'].max : a.target.value }); }} />
                                                        <span className="after_input">Per Month</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div tab_spacing">
                                    <div className="row m-0 p-0">
                                        <h3 className="calculate_field_title pb-2">Avg. No of Units consumed per month <sup>*</sup></h3>
                                        <div className="col-12">
                                            <div className="slider_area">
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between slider_div">
                                                        <span className="pe-2">0</span>
                                                        <input type="range" className="w-100 custom-range" id="electricityBill" min={calculatorProps['units-consumed'].min} max={calculatorProps['units-consumed'].max} step="1" value={(unitsConsumedPerMonth === "") ? calculatorProps['units-consumed'].min : unitsConsumedPerMonth} onChange={(a) => { setUnitsConsumedPerMonth((oldVal) => { return (a.target.value > calculatorProps['units-consumed'].max) ? calculatorProps['units-consumed'].max : a.target.value }); }} />
                                                        <span className="ps-2">4L</span>
                                                    </div>
                                                    <div className="d-flex flex-column flex-md-row justify-content-start align-items-center slider_input_div">
                                                        <input type="number" className="form-control22 get_electricityBill ms-2 me-2" min={calculatorProps['units-consumed'].min} max={calculatorProps['units-consumed'].max} value={unitsConsumedPerMonth} onChange={(a) => { setUnitsConsumedPerMonth((oldVal) => { return (a.target.value > calculatorProps['units-consumed'].max) ? calculatorProps['units-consumed'].max : a.target.value }); }} />
                                                        <span className="after_input">Units Per Month</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div tab_spacing">
                                    <div className="row m-0 p-0">
                                        <h3 className="calculate_field_title pb-2">Rooftop Area (sq. ft.) <sup>*</sup></h3>
                                        <div className="col-12">
                                            <div className="slider_area">
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between slider_div">
                                                        <span className="pe-2">0</span>
                                                        <input type="range" className="w-100 custom-range" id="electricityBill" min={calculatorProps['roottop-area'].min} max={calculatorProps['roottop-area'].max} step="1" value={(rooftopArea === "") ? calculatorProps['roottop-area'].min : rooftopArea} onChange={(a) => { setRooftopArea((oldVal) => { return (a.target.value > calculatorProps['roottop-area'].max) ? calculatorProps['roottop-area'].max : a.target.value }); }} />
                                                        <span className="ps-2">3L</span>
                                                    </div>
                                                    <div className="d-flex flex-column flex-md-row justify-content-start align-items-center slider_input_div">
                                                        <input type="number" className="form-control22 get_electricityBill ms-2 me-2" min={calculatorProps['roottop-area'].min} max={calculatorProps['roottop-area'].max} value={rooftopArea} onChange={(a) => { setRooftopArea((oldVal) => { return (a.target.value > calculatorProps['roottop-area'].max) ? calculatorProps['roottop-area'].max : a.target.value }); }} />
                                                        <span className="after_input">Sq. Ft.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 right_side_div d-flex align-items-center justify-content-center">
                            <div className="row space_left_side d-flex align-items-center justify-content-center mx-auto cal-right-section-width">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="w-100 d-flex justify-content-center align-items-center pb-5">
                                        <CircularSlider
                                            dataIndex={calculatorProps["default-years"]-1}
                                            width={280}
                                            valueFontSize="6rem"
                                            valueFontColor="red"
                                            label="Years"
                                            labelColor="#000"
                                            knobColor="#fff"
                                            progressColorFrom="rgb(237, 28, 36)"
                                            progressColorTo="rgb(237, 28, 36)"
                                            progressSize={25}
                                            trackColor="#eeeeee"
                                            trackSize={25}
                                            min={1}
                                            max={25}
                                            onChange={ (value) => { setNoOfYears(value) } }
                                        />
                                    </div>
                                    {/* <div className="w-100 d-flex flex-column align-items-start">
                                        <div className="d-flex align-items-center">
                                            <h4 className="cal-title">Total Savings with Structure :<span className="cal-value">&nbsp;&#8377;{totalSavingsWithStructure.toFixed(2)} Crores</span></h4>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h4 className="cal-title">Total Savings without Structure:<span className="cal-value">&nbsp;&#8377;{totalSavingsWithoutStructure.toFixed(2)} Crores</span></h4>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h4 className="cal-title">System Price With Strucutre :<span className="cal-value">&nbsp;&#8377;{systemPriceWithStructure.toFixed(2)} Crores</span></h4>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h4 className="cal-title">System Price Without Structure :<span className="cal-value">&nbsp;&#8377;{systemPriceWithoutStructure.toFixed(2)} Crores</span></h4>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h4 className="cal-sub-title">System Size :<span className="cal-sub-value">&nbsp;{systemSize.toFixed(2)} kWp</span></h4>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h4 className="cal-sub-title">Output per month :<span className="cal-sub-value">&nbsp;{outputPerMonth.toFixed(2)} units</span></h4>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <div className="w-100 cal-box">
                                        <div className="w-100 d-flex align-items-center justify-content-center mb-3"><h3 className="m-0 p-0 text-uppercase fs-5 fw-bold primary-text">Total Savings</h3></div>
                                        <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between align-items-center">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="cal-title">With Structure</div>
                                                <div className="cal-value">&nbsp;&#8377;{totalSavingsWithStructure.toFixed(2)}</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center align-items-center mobile-top-space">
                                                <div className="cal-title">Without Structure</div>
                                                <div className="cal-value">&nbsp;&#8377;{totalSavingsWithoutStructure.toFixed(2)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 cal-box mt-3">
                                        <div className="w-100 d-flex align-items-center justify-content-center mb-3"><h3 className="m-0 p-0 text-uppercase fs-5 fw-bold primary-text">System Price</h3></div>
                                        <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between align-items-center">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="cal-title">With Structure</div>
                                                <div className="cal-value">&nbsp;&#8377;{systemPriceWithStructure.toFixed(2)}</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center align-items-center mobile-top-space">
                                                <div className="cal-title">Without Structure</div>
                                                <div className="cal-value">&nbsp;&#8377;{systemPriceWithoutStructure.toFixed(2)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="w-100 cal-box mt-3">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="cal-title text-center">System Size</div>
                                                <div className="cal-value text-center">&nbsp;{systemSize.toFixed(2)} kwp</div>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="w-100 cal-box mt-3">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="cal-title text-center">Output/per month</div>
                                                <div className="cal-value text-center">&nbsp;{outputPerMonth.toFixed(2)} units</div>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </>
  )
}

export default SolarCalculator