import React from 'react';
import { LangingPageImageUrl } from '../../../Config';
import { ImageUrl } from '../../../Config';

const AtumIntegratedSolarRoofComp = () => {
  return (
    <>
        <section className="landingISR-about">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-content py-4">
                            <h2 className="section-heading text-center">About</h2>
                            <p className="section-desp px-lg-5 mx-lg-5">With ATUM, we bring you the luxury of smart solar roofing. Seamlessly integrated with your
                                building to
                                <span className="content-span"> generate 40% more power </span>
                                than ordinary solar, ATUM is the first of its kind in the world! The sustainable roof is made using GreenPro eco-labelled materials that can
                                <span className="content-span"> last over 50 years </span>
                                and can be a great alternative to metal sheets, sloped RCC or clay tiles. With no power bills to pay and savings from day one of installation,
                                ATUM rewards you with a payback period of just 2.5 years.
                            </p>
                            <p className="section-desp">
                                A roof so smart, ATUM lets you track your clean energy and savings on your smartphone in real time.
                                Shielding industrial, commercial and residential buildings from harsh weathers, leaks and fire damage like no other, ATUM is a safe roof that keeps your cash flow intact.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="landingISR-key-features">
            <div className="container ">
                <div className="row box-bg">
                    <div className="col-12">
                        <div className="section-content py-4">
                            <h2 className="section-heading text-center">Key Features</h2>
                            <div className="features-list p-4">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/atum_is_patented.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Atum is Patented</h5>
                                        <p className="feature-desc text-white px-2">The very first integrated solar roof</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/class_a_ rated.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Class A Fire Rated</h5>
                                        <p className="feature-desc text-white px-2">Resists fire damage for over 2.5 hrs</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/hurrican_proof.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Hurricane Proof</h5>
                                        <p className="feature-desc text-white px-2">Can withstand wind speeds of 255 kmph</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/themermal.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Thermal Resistance</h5>
                                        <p className="feature-desc text-white px-2">Keeps your interiors up to 3<sup>o</sup>C cooler</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/long_life.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Long Life</h5>
                                        <p className="feature-desc text-white px-2">It has a minimum lifespan of 25 years</p>
                                    </div>
                                    <div className="col-md-1 d-none d-md-flex"></div>
                                    <div className="col-md-1 d-none d-md-flex"></div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/more_power.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">More Power</h5>
                                        <p className="feature-desc text-white px-2">Can generate up to 40% more power</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/sturdy.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Sturdy</h5>
                                        <p className="feature-desc text-white px-2">Fire, water, radiation and shock resistant</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/payback.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Payback Period</h5>
                                        <p className="feature-desc text-white px-2">As low as 2.5 years for complete payback</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/load_bearing.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Load Bearing</h5>
                                        <p className="feature-desc text-white px-2">A live load capacity of 300 kg per sqft</p>
                                    </div>
                                    <div className="col-6 col-md-2 text-center px-0 pt-2">
                                        <img src={LangingPageImageUrl+'images/features/leak_proof.png'} alt="Feature" className="img-fluid p-1 mb-3" />
                                        <h5 className="feature-title py-1">Leak-proof</h5>
                                        <p className="feature-desc text-white px-2">Guaranteed 100% leak-proof roof</p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="patented" style={{backgroundImage:`url(${ImageUrl}landing-pages/images/atum-impose.png)`}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center py-5">
                        <img src={LangingPageImageUrl+'images/product.png'} width="40%" alt="20 Years Printed" />
                    </div>
                </div>
            </div>
        </section>
        <section className="landingISR-certificates">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-content py-4">
                            <h2 className="section-heading text-center">Certifications</h2>
                            <div className="row mt-3 py-4 align-items-center">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                    <img src={LangingPageImageUrl+'images/certificates/loan_master_logo.png'} alt="Certification" className="img-fluid" />
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                    <img src={LangingPageImageUrl+'images/certificates/bis-certification-service.png'} alt="Certification" className="img-fluid" />
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                    <img src={LangingPageImageUrl+'images/certificates/ul-logo.png'} alt="Certification" className="img-fluid" />
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                    <img src={LangingPageImageUrl+'images/certificates/tuv.png'} alt="Certification" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="landingISR-featuredOnSection">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-content pt-4">
                            <h2 className="section-heading text-center">Featured On</h2>
                            <div className="row mt-5">
                                <div className="col-lg-2 col-md-3  col-4 mb-3 text-center">
                                    <img src={LangingPageImageUrl+'images/featuredon/entrepreneur-india.png'} alt="Featured On Images" className="img-fluid" />
                                </div>
                                <div className="col-lg-2 col-md-3  col-4 mb-3  text-center">
                                    <img src={LangingPageImageUrl+'images/featuredon/forbes-India.png'} alt="Featured On Images" className="img-fluid" />
                                </div>
                                <div className="col-lg-2 col-md-3  col-4 mb-3  text-center">
                                    <img src={LangingPageImageUrl+'images/featuredon/better-india.png'} alt="Featured On Images" className="img-fluid" />
                                </div>
                                <div className="col-lg-2 col-md-3 col-4 mb-3  text-center">
                                    <img src={LangingPageImageUrl+'images/featuredon/telangana-today.png'} alt="Featured On Images" className="img-fluid" />
                                </div>
                                <div className="col-lg-2 col-md-3  col-4 mb-3  text-center">
                                    <img src={LangingPageImageUrl+'images/featuredon/the-hindu.png'} alt="Featured On Images" className="img-fluid" />
                                </div>
                                <div className="col-lg-2 col-md-3  col-4  mb-3 text-center">
                                    <img src={LangingPageImageUrl+'images/featuredon/the-new-indian-express.png'} alt="Featured On Images" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="landingISR-gallerySection">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="section-content py-4">
                            <h2 className="section-heading text-center">Gallery</h2>
                            <div id="landingISRGalleryCarousel" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/1.jpg'} alt="First slide" />
                                            </div>
                                            <div className="col-4">
                                                <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/2.jpg'} alt="First slide" />
                                            </div>
                                            <div className="col-4">
                                                <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/3.jpg'} alt="First slide" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/4.png'} alt="First slide" />
                                            </div>
                                            <div className="col-4">
                                                <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/2.jpg'} alt="First slide" />
                                            </div>
                                            <div className="col-4">
                                                <img className="d-block w-100 galleryImg-height" src={LangingPageImageUrl+'images/gallery/3.jpg'} alt="First slide" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#landingISRGalleryCarousel" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#landingISRGalleryCarousel" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AtumIntegratedSolarRoofComp